
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from "react-hook-form";

import {
  // Redirect,
  Navigate,
  useLocation,
} from "react-router-dom";

import {
  TextField,
  PrimaryButton,
  Image,
  ImageFit,
  getTheme,
  DefaultButton,
} from '@fluentui/react'

import { AUTHENTICATION } from '../common/actions'

import { useStoreDispatch, useStoreState } from '../common/storeContext'
import { useMiddletier } from '../common/middletier'
import ModuleField from './components/ModuleField';

const theme = getTheme();

const LoginForm = () => {
  const appState = useStoreState()
  const dispatch = useStoreDispatch()
  const { mutation, query } = useMiddletier()
  const location = useLocation()
  const methods = useForm({ defaultValues: {}, })
  const { handleSubmit } = methods

  const [user, setUser] = useState({ username: '', password: '', authenticationType: 'SYSTEM' })
  const [authenticationTypes, setAuthenticationType] = useState([])
  const [enabledSSO, setEnabledSSO] = useState(false)
  const [loginWithToken, setLoginWithToken] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')

  const onClickLogin = (e) => {
    mutation([
      {
        // index: 0,
        method: 'loginV2',
        params: [
          { code: 'username', graphqlType: 'String', required: true, value: user.username },
          { code: 'password', graphqlType: 'String', required: true, value: user.password },
          { code: 'authenticationType', graphqlType: 'String', value: user.authenticationType },
        ],
        attributes: []
      }
    ])
      .then(({ data }) => {
        localStorage.setItem('USER_NAME', user.username)
        localStorage.setItem('USER_AUTHENTICATION_TYPE', user.authenticationType)
        dispatch({ type: AUTHENTICATION.LOGIN, payload: { user: data.loginV2.user, modules: data.loginV2.modules, roles: [...data.loginV2.user.roles], profile: data.loginV2.profile } })
      })
      .catch((error) => {
        console.error('error: ', error)
        if (error.graphQLErrors) {
          error.graphQLErrors.forEach((_error) => {
            setErrorMessage(_error.message)
          });
        }
      })
    // e.preventDefault();
  }

  useEffect(() => {
    mutation([
      {
        // index: 0,
        method: 'loginWithToken',
        params: [],
        attributes: []
      }
    ])
      .then(({ data }) => {
        dispatch({ type: AUTHENTICATION.LOGIN, payload: { user: data.loginWithToken.user, modules: data.loginWithToken.modules, roles: [...data.loginWithToken.user.roles], profile: data.loginWithToken.profile } })
        setLoginWithToken(true)
      })
      .catch((error) => {
        setLoginWithToken(true)
      })

    // console.log('USERNAME:', localStorage.getItem('USER_NAME'))
    if (localStorage.getItem('USER_NAME')) {
      setUser({ username: localStorage.getItem('USER_NAME'), password: '', authenticationType: localStorage.getItem('USER_AUTHENTICATION_TYPE') ?? 'SYSTEM' })
    }
  }, [mutation, dispatch]);

  useEffect(() => {
    const method = 'getSiteSettings'
    const params = []
    const attributes = []

    query({ method, params, attributes })
      .then(({ data }) => {
        setAuthenticationType(data[method].authenticationTypes)
        const _enabledSSO = data[method].sso && data[method].sso.length > 0 ? true : false
        setEnabledSSO(_enabledSSO)
      })
      .catch((error) => {
      })
  }, [query])

  return (
    <React.Fragment>
      {appState && appState.loggedIn ?
        <Navigate
          to={{
            pathname: location.state && location.state.from ? location.state.from : "/",
            ...((location && location.state && location.state.search) && {search: location.state.search})
          }}
        />
        :
        <>
          {loginWithToken === true ?
            <div style={{ position: 'relative', height: '100%', width: '100%', margin: '0', padding: '0', backgroundColor: theme.palette.neutralLight }}>
              <div style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', overflow: 'hidden' }}>
                <div style={{ display: 'table', height: '100%', width: '100%' }}>
                  <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                    <div style={{ position: 'relative', marginLeft: 'auto', marginRight: 'auto', width: '420px', height: '350px', backgroundColor: theme.palette.white, padding: theme.spacing.m, boxShadow: theme.effects.elevation4 }}>
                      <div style={{ height: '100%', width: '100%', display: 'flex', flexWrap: 'nowrap', flexDirection: 'column', alignContent: 'center', justifyContent: 'center' }}>
                        <div style={{ marginBottom: '35px' }}>
                          <Image
                            styles={{ 'image': { width: '' } }}
                            src={'/logo.png'}
                            imageFit={ImageFit.cover}
                            alt='Example of the image fit value "cover" on an image wider than the frame.'
                            // width={150}
                            height={50}
                          />
                        </div>
                        <FormProvider {...methods} >
                          <form>
                            {/* <form action="#" method="get" onSubmit={onClickLogin}> */}
                            <ModuleField
                              styles={{ root: { marginBottom: '20px' } }}
                              autoComplete="off"
                              // showKey={true}
                              setKey="key"
                              columns={[{ key: '_label', text: 'Name' }, { key: 'key', text: 'Value' }]}
                              items={authenticationTypes ?? []}
                              value={user.authenticationType}
                              onChange={(newValue) => { setUser({ ...user, authenticationType: newValue }) }}
                            />
                            <TextField
                              styles={{ root: { marginBottom: '20px' } }}
                              placeholder="Email"
                              autoComplete="off"
                              value={user.username}
                              onChange={(e, newValue) => { setUser({ ...user, username: newValue }) }}
                            />
                            <TextField
                              styles={{ root: { marginBottom: '35px' } }}
                              autoComplete="off"
                              type='password'
                              placeholder="Password"
                              value={user.password} onChange={(e, newValue) => { setUser({ ...user, password: newValue }) }}
                              errorMessage={errorMessage}
                            />
                            {/* </form> */}
                          </form>
                        </FormProvider>
                        <div style={{ display: 'flex' }}>
                          <PrimaryButton type="submit" text="Login" allowDisabledFocus onClick={handleSubmit(onClickLogin)} />
                          <div style={{ flex: 1 }}></div>
                          {enabledSSO ?
                            <DefaultButton text="SSO" allowDisabledFocus onClick={() => { window.location.href = '/sso_login' }} /> : <></>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : <></>}
        </>
      }
    </React.Fragment>
  )
}

export default LoginForm;