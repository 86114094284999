import { useRef, useEffect, useCallback } from 'react'

import {
  gql,
  useApolloClient
} from '@apollo/client'


function useMemoCompare(next, compare) {
  // Ref for storing previous value
  const previousRef = useRef();
  const previous = previousRef.current;

  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isEqual = compare(previous, next);

  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });

  // Finally, if equal then return the previous value
  return isEqual ? previous : next;
}

const GQL_GET_LISTS = gql`query lists($filter: JSON!){
  lists(filter: $filter){
    key: value
    text: name
    id
    listGroup
    name
    order
    parent
    selectable
    updateCtr
    value
    _label
    listGroup_label
    parent_label
  }
}`

const _getAttributeString = (attribute) => {
  if (typeof (attribute) === 'string') {
    return `${attribute}\n`
  }
  else if (typeof (attribute) === 'object' && attribute.length > 1) {
    if (typeof (attribute[1]) === 'string') {
      return `${attribute[1]}: ${attribute[0]}\n`
    }
    else {
      let attributes = []
      for (let i = 0; i < attribute[1].length; i++) {
        attributes.push(_getAttributeString(attribute[1][i]))
      }

      return `${attribute[0]} {\n ${attributes.join(',\n')} }\n`
    }
  }
}

const _getFromObjectsWithParams = ({ index = '', method, params, attributes }) => {
  let _params = {}, _paramsType = [], _paramsKey = []
  for (let i = 0; i < params.length; i++) {
    if (typeof (params[i]) === 'object') {
      _params[`${params[i].code}${index}`] = params[i].value
      _paramsType[_paramsType.length] = `$${params[i].code}${index}: ${params[i].graphqlType}${params[i].required ? '!' : ''}`
      _paramsKey[_paramsKey.length] = `${params[i].code}: $${params[i].code}${index}`
    }
  }

  let _attributes = ''
  for (let i = 0; i < attributes.length; i++) {
    _attributes += _getAttributeString(attributes[i])
  }

  if (_attributes.length > 0) {
    _attributes = `{${_attributes}}`
  }

  return _paramsKey.length > 0 ?
    {
      gql: `${index !== '' ? index + ' : ' : ''}${method}(${_paramsKey.join(', ')})${_attributes}`,
      params: { ..._params },
      paramsType: [..._paramsType]
    } :
    {
      gql: `${index !== '' ? index + ' : ' : ''}${method} ${_attributes}`,
      params: {},
      paramsType: []
    }
}

function useMiddletier() {
  const client = useApolloClient()

  const getQueryFromObjects = ({ method, attributes }) => {
    if (!attributes || !method || attributes.length === 0 || method === '') {
      return `query ${method}($filter: JSON!){\n${method}(filter: $filter)}`
    }

    let _attributes = ''
    for (let i = 0; i < attributes.length; i++) {
      _attributes += _getAttributeString(attributes[i])
    }

    if (_attributes.length > 0) {
      _attributes = `{${_attributes}}`
    }

    return `query ${method}($filter: JSON!){\n${method}(filter: $filter)${_attributes}}`
  }

  const getQueryFromObjectsWithParams = ({ index = '', method, params, attributes }) => {
    return _getFromObjectsWithParams({ index, method, params, attributes })
  }

  const getMutationFromObjectsWithParams = ({ index = '', method, params, attributes }) => {
    return _getFromObjectsWithParams({ index, method, params, attributes })
  }

  const getLists = useCallback(({ listGroup = '', where = {}, order = [], fetchPolicy = 'network-only' }) => {
    return client.query(
      {
        query: GQL_GET_LISTS,
        fetchPolicy,
        variables: { filter: { where: { listGroup, ...where }, order } }
      })
  }, [client])

  const getModule = useCallback(({ module = '', attributes = [], where = {}, order = [], fetchPolicy = 'network-only' }) => {
    const _query = getQueryFromObjects({ method: module, attributes })

    if (_query) {
      return client.query(
        {
          query: gql`${_query}`,
          fetchPolicy,
          variables: { filter: { where, order } }
        })
    }
    else {
      return new Error('module or attributes is empty.')
    }
  }, [client])

  const query = useCallback(({ method = null, params = [], attributes = [], fetchPolicy = 'network-only' }) => {
    const _query = getQueryFromObjectsWithParams({ method, params, attributes })

    if (_query) {
      // try {
      return client.query(
        {
          query: gql`query ${method} ${_query.paramsType.length > 0 ? '(' + _query.paramsType.join() + ')' : ''} {\n${_query.gql} \n}`,
          fetchPolicy,
          variables: { ..._query.params }
        })
      // } catch (error) {
      //   return new Error('method or attributes is empty.')
      // }
    }
    else {
      return new Error('method or attributes is empty.')
    }
  }, [client])

  const queries = useCallback((query = []) => {
    let _gql = '', _params = {}, _paramsType = []
    for (let i = 0; i < query.length; i++) {
      const { index, method, attributes = [], params } = query[i]
      const _query = getQueryFromObjectsWithParams({ index, method, attributes, params })

      _gql += _query.gql + '\n'
      if (_query.paramsType.length > 0) {
        _paramsType.splice(_paramsType.length, 0, ..._query.paramsType)
        _params = { ..._params, ..._query.params }
      }
    }

    if (_gql.length > 0) {
      return client.mutate(
        Object.keys(_params).length > 0 ?
          {
            mutation: gql`query multipleQuery(${_paramsType.join()}) {\n${_gql} \n}`,
            variables: { ..._params }
          } :
          {
            mutation: gql`query multipleQuery {\n${_gql} \n}`,
            variables: {}
          }
      )
    }
    else {
      return new Error('module or attributes is empty.')
    }
  }, [client])

  const findAll = useCallback(({ method = '', attributes = [], where = {}, order = [], limit = 0, fetchPolicy = 'network-only' }) => {
    const _query = getQueryFromObjects({ method, attributes })

    if (_query) {
      return client.query(
        {
          query: gql`${_query}`,
          fetchPolicy,
          variables: { filter: { where, order, ...(limit > 0 && { limit }) } }
        })
    }
    else {
      return new Error('module or attributes is empty.')
    }
  }, [client])

  const save = useCallback(({ method = '', attributes = [], params = [] }) => {
    const _query = getMutationFromObjectsWithParams({ method, attributes, params })

    if (_query) {
      return client.mutate(
        {
          mutation: gql`mutation ${method}(${_query.paramsType.join()}) {\n${_query.gql} \n}`,
          variables: { ..._query.params }
        })
    }
    else {
      return new Error('module or attributes is empty.')
    }
  }, [client])

  const destroy = useCallback(({ method = '', params = [] }) => {
    const _query = getMutationFromObjectsWithParams({ method, attributes: [], params })

    if (_query) {
      return client.mutate(
        {
          mutation: gql`mutation ${method}(${_query.paramsType.join()}) {\n${_query.gql} \n}`,
          variables: { ..._query.params }
        })
    }
    else {
      return new Error('module or attributes is empty.')
    }
  }, [client])

  const mutation = useCallback((mutation = []) => {
    let _gql = '', _params = {}, _paramsType = []
    for (let i = 0; i < mutation.length; i++) {
      const { index, method, attributes = [], params } = mutation[i]
      const _query = getMutationFromObjectsWithParams({ index, method, attributes, params })

      _gql += _query.gql + '\n'
      if (_query.paramsType.length > 0) {
        _paramsType.splice(_paramsType.length, 0, ..._query.paramsType)
        _params = { ..._params, ..._query.params }
      }
    }

    if (_gql.length > 0) {
      return client.mutate(
        Object.keys(_params).length > 0 ?
          {
            mutation: gql`mutation multipleMutation(${_paramsType.join()}) {\n${_gql} \n}`,
            variables: { ..._params }
          } :
          {
            mutation: gql`mutation multipleMutation {\n${_gql} \n}`,
            variables: {}
          }
      )
    }
    else {
      return new Error('module or attributes is empty.')
    }
  }, [client])

  return { getLists, getModule, query, findAll, save, destroy, mutation, queries }
}

export { useMemoCompare, useMiddletier }